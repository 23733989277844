/* Container design */

.container-t2 {
  background: #f1f1f1;
  border-radius: 10px;
}

.container-t1 {
  background: #fff;
  border-radius: 10px;
}

/* Text design */

.text-row {
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.subtitle {
  margin-bottom: 5vh;
}

.subtitle-1 {
  font-size: 10;
}

/* Divisor and footer design */

.divisor {
  border-top: 2px solid #8c52ff;
  border-radius: 0 !important;
}

.rule-label {
  border-bottom: 2px solid #8c52ff;
  padding-bottom: 3px;
}

.foot {
  padding: 0;
  margin: 0;
  margin-left: 5vw;
}

.rule-foot {
  padding: 0;
  padding-top: 30px;
  margin: 0;
  margin-left: 5vw;
}
