@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
/* Box formatting */

.box {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: -2px;
  border-radius: 2px;
  height: 20px;
  width: 20px;
}

.lg-box {
  display: inline-block;
  border: 2px solid black;
  border-radius: 2px;
  height: 30px;
  width: 30px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.statbox-row {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Grid formatting */

.grid {
  background: #2f2e2e;
  border-top: 5px solid #2f2e2e;
  padding-left: 5px;
  border-radius: 5px;
}

/* Navbar formatting */

.nav_bar {
  background-color: #ffffff;
}

/* Icon formatting */

@media (min-width: 770px) {
  .icon {
    margin-right: 10px;
    margin-left: 40px;
  }
}

@media (max-width: 770px) {
  .icon {
    margin-right: 10px;
    margin-left: 20px;
  }
}

.mid {
  margin-bottom: 5px;
}

/* Button formatting */

.button_g {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

/* Graph formatting */

.graph {
  margin-left: -40px;
}

/* Jumbotron formatting */

.jumbotron {
  background: #fff;
  border-top: 2px solid #8c52ff;
  margin: 0;
  padding: 0;
  padding-top: 1vh;
  padding-left: 2vw;
  border-radius: 0;
}

.jumbo-row {
  padding-left: 5vw;
}

.jumbo-title {
  font-size: calc(28px + (50 - 28) * ((100vw - 360px) / (1400 - 360)));
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 30vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

/* Button formatting */

.reg-btn {
  background: #8c52ff;
  border: 2px solid #8c52ff;
  margin-top: 10px;
}

.reg-btn:hover {
  background: #6f2afa;
  border: 2px solid #6f2afa;
}

.reg-btn:disabled {
  background: #0b0020;
  border: 2px solid #0b0020;
}

/* Portrait formatting */
.tag {
  margin-left: 20px;
}

.portrait-row {
  padding-bottom: 8vh;
}

.im-text {
  padding-bottom: 5vh;
}

/* Contact formatting */
.contact-form {
  margin-bottom: 5vh;
}

/* Footer formatting */
.foot {
  padding: 0;
  margin: 0;
  margin-left: 5vw;
}

/* Title formatting */
.title {
  border-bottom: 2px solid #8c52ff;
  padding-bottom: 3px;
}

/* Thumbnail and video */

.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  transition: opacity 400ms ease 0ms;
}

.vid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
}

/* Rule tab formatting */

.game_tab {
  padding: 1px;
  border: 2px solid #8c52ff;
  border-radius: 10px;
}

.rule_col {
  padding-right: 0;
  padding-left: 0px;
  height: 100%;
}

.rule_img {
  padding-top: 20px;
}

.rule_row {
  padding: 30px;
}

.rule_text {
  font-size: 20px;
}

.nav-pills .nav-link.active {
  background-color: #8c52ff !important;
  border-radius: 0;
}

.nav-tabs .nav-link {
  width: 25%;
}

@media (min-width: 575px) {
  .rule_col {
    border-right: 2px solid #8c52ff;
  }

  .rule_tab {
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
  }

  .rule_navit {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 33.333%;
  }
}

/* Checkbox formatting */

.check-outbox {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  border: 2px solid #8c52ff;
}

.form-check-label {
  font-size: calc(15px + (20 - 15) * ((100vw - 360px) / (1400 - 360)));
}

@media (min-width: 420px) {
  .form-check-inline {
    padding-left: 20px;
  }
}

/* Mouse message formatting */

.mouse-msg {
  font-size: calc(15px + (20 - 15) * ((100vw - 360px) / (1400 - 360)));
  color: #8c52ff;
}

/* Container design */

.container-t2 {
  background: #f1f1f1;
  border-radius: 10px;
}

.container-t1 {
  background: #fff;
  border-radius: 10px;
}

/* Text design */

.text-row {
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.subtitle {
  margin-bottom: 5vh;
}

.subtitle-1 {
  font-size: 10;
}

/* Divisor and footer design */

.divisor {
  border-top: 2px solid #8c52ff;
  border-radius: 0 !important;
}

.rule-label {
  border-bottom: 2px solid #8c52ff;
  padding-bottom: 3px;
}

.foot {
  padding: 0;
  margin: 0;
  margin-left: 5vw;
}

.rule-foot {
  padding: 0;
  padding-top: 30px;
  margin: 0;
  margin-left: 5vw;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

